/*In order for the styles to be applied correctly to the tooltips and popovers, the following is important:
- The tippy configuration must be defined on module level (provideTippyConfig). Note that many properties can also be set inline, e.g. [tpVariation]
- Import the tippy directive on module level
- Import the needed tippy styles below, e.g. light.css for the light theme or scale-subtle.css for the subtle scale animation
- Import this file either into the global styles or for a web component into the topmost / root component. This is usually the one with ViewEncapsulation.ShadowDom 
- Very important: Angular's view encapsulation wraps the styles, this means that importing the styles on component level only works with ViewEncapsulation.ShadowDom or None */
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
@import "tippy.js/animations/scale-subtle.css";

.tippy-box[data-theme~="colormass-tooltip"] {
    background-color: var(--cm-tooltip-background-color, #616161);
    line-height: normal;
    font-size: var(--cm-tooltip-font-size, 12px);
}

.tippy-box[data-theme~="colormass-popover"] {
    color: #26323d;
    background-color: #fff;

    .tippy-content {
        padding: 0;
    }
}

.tooltip-container {
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    outline: 0;
    border: 0;
    margin: 0;
}
