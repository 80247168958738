@use "settings";

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

* {
    font-family: settings.$cm-font;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

/*Remove firefox dotted outline for active links and buttons: https://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links*/
button:focus {
    outline: none;
}

a {
    text-decoration: none;
    color: inherit;
}

a:focus {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.tippy-content {
    padding: 0px;
}
