//Colors

/*Breakpoints*/
$breakpoints: (
    "desktop": 1200px,
    "tablet": 992px,
    "mobile-landscape": 767px,
    "mobile": 478px,
    "mobile-small": 320px,
);

$height-breakpoints: (
    "large": 1000px,
    "medium": 800px,
    "small": 620px,
);

$background01: #fafafa;
$border-color01: #f5f5f5;

$pink: #ff3366;
$red: #dc4731;
$blue: #40c4ff;
$yellow: #f6b62b;
$green: #22a033;
$light: #f6f6f6;
$light-grey: #f1f1f1;
$dark: #000;
$dark-grey: #898989;
$light-blue: #f4f5f7;
$dark-blue: #172029;
$dark-blue-2: #3b4850;
$purple: #9763ff;

$spacer-2: 0.125rem;
$spacer-4: 0.25rem;
$minus-spacer-4: -0.25rem;
$spacer-8: 0.5rem;
$minus-spacer-8: -0.5rem;
$spacer-12: 0.75rem;
$spacer-16: 1rem;
$spacer-20: 1.25rem;
$spacer-24: 1.5rem;
$spacer-28: 1.75rem;
$spacer-32: 2rem;
$spacer-40: 2.5rem;
$spacer-48: 3rem;
$spacer-80: 5rem;
$spacer-128: 8rem;
$spacer-160: 10rem;
$spacer-208: 13rem;
$spacer-240: 15rem;
$spacer-272: 17rem;
$spacer-288: 18rem;
$spacer-320: 20rem;
$spacer-400: 25rem;

$font-8: 0.5rem;
$font-10: 0.6rem;
$font-12: 0.75rem;
$font-14: 0.875rem;
$font-16: 1rem;
$font-20: 1.25rem;
$font-24: 1.5rem;
$font-32: 2rem;
$font-64: 4rem;

$menu-height: $spacer-48;
$sidebar-width: 240px;

$border-radius: $spacer-4;

$node-padding: $spacer-12;
$cm-font:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Ubuntu,
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;

$job-state-default: $dark-blue-2;
$job-state-success: $green;
$job-state-error: $red;
$job-state-warning: $yellow;
$job-state-cancelled: $pink;
$job-state-init: $dark-grey;
$job-state-runnable: $purple;
$job-state-running: $blue;
$job-state-light: #6f6f6f;
