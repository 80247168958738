@use "mixins";

.cm-dialog {
    width: 1200px;
    height: 95vh;
    min-width: 50vw;
    max-width: 90vw;
    position: static;
}

.cm-dialog--small {
    width: 930px;
    max-width: 930px;

    @include mixins.screensize("desktop") {
        width: 85vw;
    }

    @include mixins.screensize("mobile") {
        width: 100%;
    }
}

.cm-dialog--medium {
    width: 85vw;
    max-width: 1200px;

    @include mixins.screensize("mobile") {
        width: 100%;
    }
}

.cm-dialog--large {
    width: 98vw;

    @include mixins.screensize("mobile") {
        width: 100%;
    }
}
