@use "@angular/material" as mat;
@use "settings";
@use "colors";

@include mat.elevation-classes();
@include mat.app-background();

$colormass-app-primary: mat.m2-define-palette(mat.$m2-light-blue-palette);
$colormass-app-accent: mat.m2-define-palette(mat.$m2-light-blue-palette, A200, A100, A400);
$colormass-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$colormass-app-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $colormass-app-primary,
            accent: $colormass-app-accent,
            warn: $colormass-app-warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

// define all component themes at once - not the most efficient way,
// but we use most of the components anyway, so this should be fine
@include mat.all-component-themes($colormass-app-theme);

.mat-mdc-tooltip-panel {
    // make buttons underneath tooltips clickable
    // essentially trying to undo this PR, recreating previous behaviour
    // https://github.com/angular/components/pull/24475
    pointer-events: none !important;
}

.mat-mdc-form-field-infix {
    padding-top: settings.$spacer-4 !important;
    padding-bottom: settings.$spacer-4 !important;
    min-height: 0 !important;
}

.mat-mdc-card-header-text {
    width: 100%;
}

// our special text fields have no background and minimal horizontal padding
.mat-mdc-text-field-wrapper.mdc-text-field--filled {
    padding: 0 settings.$spacer-4 !important;
}

.mat-mdc-dialog-content {
    // Angular Material likes to restrict the content height to 65vh,
    // which does not look good at all :(
    max-height: 100vh !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    padding: 0 settings.$spacer-4 !important;
}

//.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
//    max-width: calc(150% + 1px) !important;
//}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(4px, var(--mdc-outlined-text-field-container-shape)) * 2) !important;
}

.mdc-notched-outline .mdc-notched-outline__leading {
    width: max(2px, var(--mdc-outlined-text-field-container-shape)) !important;
}

:root {
    --mat-card-title-text-size: 24px;
    --mat-card-title-text-line-height: 60px;
    --mat-form-field-container-text-size: 14px;
    --mat-form-field-outlined-label-text-populated-size: 12px;
    --mat-form-field-state-layer-color: transparent;
    --mat-mdc-form-field-floating-label-scale: 1;
    --mat-paginator-container-background-color: transparent;
    --mat-select-trigger-text-size: 14px;

    --mdc-dialog-container-color: #{colors.$gray-100};
    --mdc-dialog-supporting-text-line-height: 1rem;
    --mdc-filled-button-label-text-color: white;
    --mdc-filled-text-field-container-color: transparent;
}

[hidden] {
    display: none !important;
}

.cm-datepicker .mat-mdc-form-field-flex {
    align-items: center !important;
}
