@use "settings";
@use "reset";
@use "colors";
@use "buttons";
@use "dialogs";
@use "fonts";

.cm-app-main-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
}

.cm-app-main-container-2 {
    width: 100vw;
}

.cm-customer-autocomplete {
    max-height: 400px !important;
}

// Loading

.cm-load-text {
    margin-top: 15vh;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #3e454c;
}

.cm-loading-dots {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.cm-loading-dots div {
    position: absolute;
    // Align the middle dot with the pipe of the colormass logo
    margin-left: -2px;
    top: 25px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e454c;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.cm-loading-dots div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.cm-loading-dots div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.cm-loading-dots div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.cm-loading-dots div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.cm-untruncated-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}

.cm-loading-placeholder {
    position: relative;
}

.cm-loading-placeholder.cm-loading-placeholder--loading {
    &:after {
        content: "";
        background: linear-gradient(to right, colors.$slate-100 0%, colors.$gray-100 50%, colors.$slate-100 100%);
        border-radius: 6px;
        display: inline-block;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
    }

    &.cm-loading-placeholder--dark:after {
        background: linear-gradient(to right, colors.$slate-200 0%, colors.$gray-200 50%, colors.$slate-200 100%);
    }
}

.cm-disabled {
    pointer-events: none;
}

.tippy-box {
    // prevent large job task messages from overflowing the tooltip
    overflow: hidden;
}

.cm-dragging-cursor * {
    cursor: row-resize !important;
}
