@use "colors";
@use "settings";

.cm-simple-window-header-button {
    background: transparent;
    border: none;
    color: colors.$black;
    cursor: pointer;
    font-size: settings.$font-14;
    padding: 0 settings.$spacer-4;
    margin-left: settings.$spacer-4;

    &:hover {
        color: colors.$gray-800;
    }

    &:focus {
        outline: none;
        color: colors.$gray-700;
    }
}

.cm-simple-icon-button {
    background: transparent;
    border: none;
    color: colors.$black;
    cursor: pointer;
    font-size: settings.$font-14;
    padding: settings.$spacer-8;

    &:hover {
        color: colors.$gray-800;
    }

    &:focus {
        outline: none;
        color: colors.$gray-700;
    }
}

.cm-simple-button {
    padding: 0;
    cursor: pointer;
    border-radius: 0.25rem;
    border: none;
}

.cm-simple-inert-button {
    padding: 0.25rem 0.5rem;
    background: white;
    color: black;
    cursor: pointer;
    border-radius: 0.25rem;
    border: none;
}

.cm-simple-link {
    cursor: pointer;
    text-decoration: none;
    font-size: settings.$font-12;

    &:hover {
        color: #40c4ff;
    }
}
