@use "sass:math";
@use "sass:color";
@use "settings";

// Screensizes
@mixin screensize($breakpoint) {
    @if map-has-key(settings.$breakpoints, $breakpoint) {
        @media (max-width: map-get(settings.$breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys(settings.$breakpoints)}.";
    }
}

@mixin screensizeheight($height-breakpoint) {
    @if map-has-key(settings.$height-breakpoints, $height-breakpoint) {
        @media (max-height: map-get(settings.$height-breakpoints, $height-breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$height-breakpoint}`. "
        + "Available breakpoints are: #{map-keys(settings.$height-breakpoint)}.";
    }
}

//Layout
@mixin cm-grid-large {
    display: grid;
    align-items: center;
    grid-gap: settings.$spacer-16;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    @include screensize("mobile") {
        grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    }
}

@mixin cm-grid-medium {
    display: grid;
    align-items: center;
    grid-gap: settings.$spacer-16;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}

@mixin cm-grid-small {
    display: grid;
    align-items: center;
    grid-gap: settings.$spacer-16;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
}

@mixin cm-grid-tiny {
    display: grid;
    align-items: center;
    grid-gap: settings.$spacer-8;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
}

@mixin cm-grid-custom($size, $gap) {
    display: grid;
    align-items: center;
    grid-gap: $gap;
    grid-template-columns: repeat(auto-fill, minmax($size, 1fr));
}

@mixin cm-rows($spacer) {
    display: flex;
    flex-flow: column;
    gap: $spacer;
}

@mixin cm-columns($spacer) {
    display: flex;
    gap: $spacer;
}

@mixin cm-cover-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

@mixin cm-center-contents($display: flex) {
    display: $display;
    justify-content: center;
    align-items: center;
}

@mixin cm-center-contents-vertical($display: flex) {
    display: $display;
    align-items: center;
}

@mixin cm-reset-input {
    background-color: transparent;
    border: 0 solid transparent;
    outline: none;
    text-decoration: none;
    width: 100%;
    padding: 0;
    font-size: inherit;
}

//Misc
@mixin cm-custom-scroll-bar {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background-color: #0000001a;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track:hover {
        background-color: #f4f4f4;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color: #00000040;
        border-radius: 5px;
    }
}

@mixin cm-shadow {
    box-shadow:
        0 0px 1px -1px rgb(0 0 0 / 20%),
        0 1px 1px 0 rgb(0 0 0 / 14%);
}

@mixin cm-strong-shadow {
    box-shadow:
        0 1px 1px -2px rgba(0, 0, 0, 0.2),
        0 1px 0px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

@mixin cm-draggable-node {
    display: flex;
    position: absolute;
    z-index: 1;
}

@mixin cm-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@mixin cm-button {
    user-select: none;
    border-radius: settings.$border-radius;
    cursor: pointer;
    border: none;
}

@mixin cm-reset-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    text-align: inherit;
    outline: none;
}

@mixin cm-background-dots($background-color, $color) {
    background-color: $background-color;
    background-image: radial-gradient($color 0.5px, $background-color 0.5px);
    background-size: settings.$spacer-8 settings.$spacer-8;
    background-position:
        0 0,
        10px 10px;
}

@mixin cm-background-diagonal-lines($background-color, $color) {
    background-color: $background-color;
    background-size: 6px 6px;
    background-image: repeating-linear-gradient(45deg, $color, $color 0.5px, $background-color 0, $background-color 50%);
}

//The class below has to be used together with "cm-aspect-ratio-content".
@mixin cm-aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: math.div($height, $width) * 100%;
    }
}

@mixin cm-aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@mixin node-color($base) {
    .node-background {
        fill: color.scale($base, $lightness: 90%);
        stroke: color.scale($base, $lightness: 20%);
    }
    .node-text {
        fill: $base;
        font-size: 24px;
    }
}

@mixin cm-item-list() {
    .cm-container {
        display: flex;
        justify-content: space-between;
        min-height: calc(100vh - 3rem);
    }

    .cm-entity-sidebar {
        position: sticky;
        top: settings.$menu-height;
        height: calc(100vh - #{settings.$menu-height} - 2 * #{settings.$spacer-16});

        @include screensize("mobile-landscape") {
            position: absolute;
        }
    }

    .cm-new-item-form {
        display: flex;
        flex-direction: column;
        gap: settings.$spacer-16;
    }

    .cm-new-item-dialog {
        width: 350px;
        display: flex;
        flex-direction: column;
        gap: settings.$spacer-16;
    }

    //.cm-items-list {
    //    width: calc(100% - #{settings.$sidebar-width} - 2 * #{settings.$spacer-16});
    //    margin: settings.$spacer-16;
    //
    //    @include screensize("mobile-landscape") {
    //        width: calc(100% - 2 * #{settings.$spacer-16});
    //    }
    //}
}

@mixin card-grid {
    :host {
        display: flex;
    }

    .card-container {
        min-width: 120px;
        margin: settings.$spacer-4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        flex-basis: 120px;
        max-width: 320px;

        cm-card,
        cm-card-placeholder {
            width: 100%;
            height: 100%;
        }
    }
}

@mixin new-item-dialog {
    .cm-new-item-form {
        display: flex;
        flex-direction: column;
        gap: settings.$spacer-16;
    }
}

@mixin hover-button() {
    color: settings.$dark-grey;

    &:hover {
        color: settings.$dark;
    }

    &:disabled {
        opacity: 0.5;
    }
}

@mixin job-states {
    color: settings.$job-state-default;

    &.state--init {
        color: settings.$job-state-init;
    }
    &.state--success {
        color: settings.$job-state-success;
    }
    &.state--error {
        color: settings.$job-state-error;
    }
    &.state--cancelled {
        color: settings.$job-state-cancelled;
    }
    &.state--runnable {
        color: settings.$job-state-runnable;
    }
    &.state--warning {
        color: settings.$job-state-warning;
    }
    &.state--running {
        color: settings.$job-state-running;
    }
    &.state--light {
        color: settings.$job-state-light;
    }
}

@mixin spinner {
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .cm-mini-spinner {
        animation: spin 1s linear infinite;
    }
}

// a CSS grid-based table that looks a lot like the Angular Material table, but is much simpler and more flexible
@mixin css-grid-table($columns: 5) {
    position: relative;
    background: white;
    display: grid;
    gap: 1px;
    font-size: settings.$font-14;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    align-items: center;

    * {
        min-width: 0;
    }
    .cm-ruler {
        background: rgba(0, 0, 0, 0.12);
        grid-column: 1 / span $columns;
        height: 1px;
        width: 100%;
    }
    .cm-table-row {
        display: contents;

        &:hover {
            background: rgba(0, 0, 0, 0.04);
        }
    }

    .cm-first-column {
        margin-left: 24px;
    }

    cm-table-cell {
        height: 48px;
    }

    .cm-pill {
        background-color: #f8f8f8;
        height: 20px;
        width: 80%;
        border-radius: 10px;
    }
}

@mixin css-grid-table-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 settings.$spacer-8;
    cursor: pointer;

    &.cm-centered {
        justify-content: center;
    }

    &.cm-header {
        font-weight: 500;
        cursor: initial;
        display: flex;
        align-items: center;
        padding-left: settings.$spacer-8;
        padding-right: settings.$spacer-8;
    }
}
